.button {
  background-color: #3737373a;
  border-radius: 6px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  width: fit-content !important;
  color: #fff;
  padding: 6px 18px;
  height: 36px;
}

.button:hover {
  background-color: #3737373a !important;
  color: #fff !important;
}
