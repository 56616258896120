.button {
  background-color: #5a33fc;
  border: none;
  border-radius: 6px;
  padding: 6px 22px !important;
  height: 50px !important;
  color: #fff;
  transition: all 300ms ease-in-out;
  font-size: 1.1rem !important;
  text-decoration: none;
}

.button:hover {
  color: #fff !important;
  background-color: #5a33fc !important;
  background-image: linear-gradient(to right, #7510ff, #5a33fc) !important;
  box-shadow: 10px 10px 30px #5b33fc34;
}

.content {
  transition: all 300ms ease-in-out;
  overflow: hidden;
  display: block !important;
  padding: 0 10px;
}

.icon {
  transition: all 300ms ease-in-out;
  width: 14px;
  position: absolute;
  top: 48%;
  transform: translateY(-50%);
  opacity: 0;
  left: 70%;
}

.rotateEffect {
  rotate: 0deg;
}

.button:hover .content {
  padding: 0 20px 0 0;
}

.button:hover .icon {
  animation: iconAnimation 0.3s;
  opacity: 1;
  left: calc(100% - 32px);
}

.button:hover .rotateEffect {
  rotate: -45deg;
}

@keyframes iconAnimation {
  0% {
    opacity: 0;
    left: 70%;
  }
  100% {
    opacity: 1;
    left: calc(100% - 32px);
  }
}
