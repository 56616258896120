.container {
  display: inline-block;
}

.circleDesign {
  background-color: #161021;
  padding: 10px;
  border-radius: 50%;
  border: 2px dashed rgb(56, 30, 141);
}

.bar {
  width: 15px;
  height: 5px;
  margin: 5px 0;
  background-color: rgb(53, 53, 53);
  border-radius: 1px;
}

.children {
  position: absolute;
}
