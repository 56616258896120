.container {
    height: 100vh;
    text-align: center;
    z-index: 2;
    position: relative;
    overflow: hidden;
}

.info_one {
    padding-top: 40vh;
    color: #fff;
    opacity: 0.6;
}

.header {
    color: #fff;
    font-size: 4rem;
    margin-top: 10px;
    margin-bottom: 20px;
}

.canvas {
    position: absolute;
    height: 100vh;
    width: 100vw;
    background-color: #2b0970;
    background-image: linear-gradient(#2b0970, #100722);
}