.logo {
    align-items: center;
    display: inline-flex;
}

.appLogo {
    display: inline;
    height: 35px;
}

.companyName {
    display: inline;
    margin-left: 10px;
    color: #fff;
    font-size: 2.3rem;
    font-weight: 300;
}