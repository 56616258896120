.collapseIcon {
  padding: 6px;
  background-color: #000;
  transition: 0.3s;
  border-radius: 5px;
  color: #fff !important;
}

.title {
  font-size: 1.6rem;
  font-weight: 600;
}

.title p {
  font-size: 0.9rem !important;
  font-weight: 200;
}
