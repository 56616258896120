.button {
  background-color: #fff;
  border: none;
  border-radius: 6px;
  padding: 6px 18px;
  height: 36px;
  color: #7d60fc;
  transition: all 300ms ease-in-out;
}

.button:hover {
  color: #fff !important;
  background-color: rgb(113, 113, 113) !important;
}
