@font-face {
  font-family: openSans;
  src: url(./assets/fonts/Geologica.ttf);
}

* {
  margin: 0;
  padding: 0;
  font-family: openSans;
  scroll-behavior: smooth;
  box-sizing: border-box;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.App {
  text-align: center;
}
