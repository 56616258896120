.box {
  --b: 2px;
  --s: 50px;
  --c: rgb(107, 107, 107);
  position: relative;

  width: 80%;
  padding: 55px 60px;
  margin: auto;

  clip-path: polygon(
    0 var(--s),
    var(--s) 0,
    100% 0,
    100% calc(100% - var(--s)),
    calc(100% - var(--s)) 100%,
    0 100%
  );
  background: linear-gradient(to left, #5a33fc, #241566);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.box > h1 {
  color: #fff;
  font-size: 2.1rem;
  font-weight: 400;
}

#subscribeButton:hover {
  border-radius: 12px !important;
  box-shadow: none !important;
}

.inputBox {
  display: flex;
  background-color: #fff;
  padding: 5px;
  border-radius: 10px;
}
