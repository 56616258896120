.mainContainer {
  position: relative;
  margin: 50px 0;
  display: inline-block;
  width: 290px;
  z-index: 1;
}

.container {
  padding: 40px 20px 60px 20px;
  display: inline-block;
  position: relative;
  background-color: #322b42;
  max-width: 290px;
  min-width: 260px;
  width: 100%;

  --b: 2px;
  --s: 50px;
  --c: rgb(107, 107, 107);

  border: var(--b) solid var(--c);
  clip-path: polygon(
    0 var(--s),
    var(--s) 0,
    100% 0,
    100% calc(100% - var(--s)),
    calc(100% - var(--s)) 100%,
    0 100%
  );
  background: linear-gradient(
        135deg,
        var(--c) calc(0.707 * var(--s) + var(--b)),
        #0000 0
      )
      border-box,
    linear-gradient(-45deg, var(--c) calc(0.707 * var(--s) + var(--b)), #0000 0)
      border-box,
    linear-gradient(-45deg, #1b1429, #352e45);
}

.topBox {
  position: absolute;
  padding: 15px;
  border: 2px solid rgb(58, 58, 58);
  background-color: #161021;
  left: 50%;
  transform: translateX(-50%);
  top: -40px;
  background-image: linear-gradient(#2b2539, #3e394e);
  border-radius: 2px;
}

.circle {
  padding: 20px;
  background-color: #fff;
  display: inline-block;
  border-radius: 50%;
}

.title {
  color: #fff;
  font-size: 2.1rem;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 400;
}

.details {
  text-align: center;
  color: #707070;
  line-height: 1.6rem;
  font-weight: 100;
}

.readMeButton {
  color: #fff;
  position: absolute;
  bottom: 20px;
  transform: translateX(-50%);
  left: 50%;
  cursor: pointer;
  font-size: 1.1rem;
  font-weight: 300;
}

.readMeButton > img {
  width: 10px;
  margin-left: 10px;
}
