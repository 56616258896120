.mainContainer {
  background-color: #000;
  position: relative;
}

.container {
  display: flex;
  padding: 120px 120px 20px 120px;
}

.companyDetails {
  width: 40%;
  padding-right: 150px;
}

.companyDetails > h2 {
  color: #fff;
  font-size: 2.4rem;
  font-weight: 300;
  margin-bottom: 6px;
}

.poweredBy {
  width: 200px;
}

.zkLogo {
  width: 70px;
  margin-bottom: 12px;
}

.details {
  color: #fff;
  margin-top: 30px;
  line-height: 2rem;
  font-weight: 200;
}

.content {
  width: 70%;
}

.contentItems {
  display: flex;
  justify-content: space-between;
}

.list {
  color: rgb(75, 75, 75);
  list-style-type: none;
}

.list > :first-child {
  color: #fff;
  margin-bottom: 20px;
  font-weight: 300 !important;
  font-size: 1.1rem;
}

.list > li {
  margin-bottom: 12px;
  color: #707070;
  font-weight: 100;
}

.list > li > a {
  color: rgb(75, 75, 75);
  text-decoration: none;
}

.contact {
  align-items: flex-end;
  margin-top: 60px;
}

.socialIcons > img {
  width: 18px;
}

.socialIcons > :nth-child(2) {
  margin: 0 16px;
}

.copyRight {
  color: rgb(255, 255, 255);
  text-align: center;
  padding-top: 50px;
  padding-bottom: 20px;
  font-weight: 100;
}

.colorBar {
  height: 13px;
  background-image: linear-gradient(to right, #890fff, #f46662);
  font-weight: 100;
}

.subscribeBox {
  position: absolute !important;
  top: -105px;
  left: 50%;
  transform: translateX(-50%);
}
