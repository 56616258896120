.modalWrapper {
  backdrop-filter: blur(5px);
  transition: 0.3s;
}

.modalClass > div {
  background-color: transparent !important;
}

.box {
  --b: 2px;
  --s: 50px;
  --c: rgb(107, 107, 107);
  position: relative;

  width: 630px;
  min-height: 140px;
  padding: 40px;
  margin: auto;

  border: var(--b) solid var(--c);
  clip-path: polygon(
    0 var(--s),
    var(--s) 0,
    100% 0,
    100% calc(100% - var(--s)),
    calc(100% - var(--s)) 100%,
    0 100%
  );
  background: linear-gradient(
        135deg,
        var(--c) calc(0.707 * var(--s) + var(--b)),
        #0000 0
      )
      border-box,
    linear-gradient(-45deg, var(--c) calc(0.707 * var(--s) + var(--b)), #0000 0)
      border-box,
    linear-gradient(to right, #423a55, #211a30);
  background-color: grey;
  display: flex;
  align-items: center;
}

.close {
  position: absolute;
  top: -30px;
  right: 12px;
  padding: 10px;
  background-color: #544a67;
  cursor: pointer;
  border-radius: 2px;
}

.boxImage {
  display: inline-block;
  width: 100px;
  height: 100px;
  border: 2px solid #595662;
  aspect-ratio: 1;
  margin-right: 30px;
}

.content {
  display: inline-block;
  color: #fff;
}

.content > h1 {
  margin-bottom: 15px;
}
