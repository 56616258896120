.cloud {
    width: 350px;
    height: 120px;
    background-color: #4d3f66;
    border-radius: 100px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    position: absolute;
    animation: move;
    animation-iteration-count: infinite;
    animation-duration: 15s;
    animation-timing-function: linear;
}

.cloud:after,
.cloud:before {
    content: '';
    position: absolute;
    background-color: #4d3f66;
    z-index: -1
}

.cloud:after {
    width: 100px;
    height: 100px;
    top: -50px;
    left: 50px;

    border-radius: 100px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
}

.cloud:before {
    width: 180px;
    height: 180px;
    top: -90px;
    right: 50px;
    border-radius: 200px;
    -webkit-border-radius: 200px;
    -moz-border-radius: 200px;
}

@keyframes move {
    0% {
        right: -100vw;
    }

    100% {
        right: 120vw;
    }
}