.container {
  width: 280px;
}

.container > h3 {
  color: rgb(123, 123, 123);
  font-weight: 500;
}

.container > h2 {
  color: #bebebe;
  line-height: 1.4rem;
  margin-bottom: 15px;
  margin-top: 8px;
  font-weight: 400;
  font-size: 1.5rem;
}

.container > div {
  color: #707070;
  font-weight: 100;
  font-size: 0.9rem;
  line-height: 1.05rem;
}
