.title {
  color: #fff;
  font-size: 4.3rem;
  font-weight: 400;
}

@media screen and (max-width: 1200px) {
  .title {
    font-size: 3.3rem;
    font-weight: 300;
  }
}

@media screen and (max-width: 756px) {
  .title {
    font-size: 3.3rem;
    font-weight: 300;
  }
}
